@use "mixins" as *
.gameItem
  width: calc(50% - 10px)
  max-width: calc(140px + var(--casino_gameItemSquaredBorder) * 2)
  flex: none
  border: var(--casino_gameItemSquaredBorder) solid var(--casino_gameItemSquaredBg)
  background: var(--casino_gameItemSquaredBg)
  margin: 10px 5px 0
  display: flex
  position: relative
  overflow: hidden
  border-radius: var(--casino_gameItemSquaredRadius)
  flex-direction: column
  font-size: 14px

  > div
    width: 140px
    min-height: 140px
    height: 140px
    position: relative
    margin: 0 auto
    background: none!important
    border: 0!important

    :global(.imageNext)
      width: 100%
      height: 100%
      border-radius: var(--casino_gameItemRadius)
      overflow: hidden
      display: block
      position: relative

      &::after
        content: ""
        position: absolute
        left: 0
        top: 0
        height: 24px
        width: 100%
        background: linear-gradient(to bottom, rgba(255,255,255, 0.3) 0%, rgba(255,255,255, 0.01) 100%)

      img
        width: 100%
        position: absolute
        top: 50%
        transform: translateY(-50%)

  video
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: 14px

.gameName
  @include bold
  color: #fff
  @include flex
  justify-content: center
  text-transform: capitalize
  padding: 10px 2px
  @include ellipsis
  width: 100%
  > span
    @include ellipsis
    max-width: 100%
    font-size: 16px
    line-height: 18px

.playNowBtn
  text-transform: uppercase
  min-width: 124px
  width: 100%
  height: 32px
  min-height: 32px
  background: var(--casinoCompact_playNowBtnBg)
  color: var(--casino_providerActiveColor)

  &:active
    background: var(--casinoCompact_playNowBtnActiveBg)

.tryButton
  margin: 4px 0 0
  width: 100%
  height: 28px
  min-height: 28px
  background: none
  font-weight: normal
  text-transform: capitalize
  color: rgb(var(--thirdaryColor))
  &:active
    color: var(--casinoCompact_playNowBtnActiveBg)

.unstarred
  min-width: 32px
  height: 32px
  background: rgba(0, 0, 0, .6)
  border-radius: 6px 0 var(--casino_gameItemSquaredRadius) 0
  position: absolute
  z-index: 2
  right: 0
  bottom: 0
  @include flex
  justify-content: center
  &:before
    content: ""
    background-color: rgba(255,255,255, 0.7)
    @include star(20px)
    transition: all 0.8s
    transform: rotate(0deg)
    transform-origin: center
    animation: unstarred 0.5s ease-in
    pointer-events: none

    @keyframes unstarred
      50%
        width: 26px
        height: 26px
        mask-size: 26px 26px
      100%
        width: 20px
        height: 20px
        mask-size: 20px 20px

  &.starred:before
    background-color: var(--star_activeBg)
    animation: starred 0.5s ease-in
    transform: rotate(145deg)

    @keyframes starred
      50%
        width: 26px
        height: 26px
        mask-size: 26px 26px
      100%
        width: 18px
        height: 18px
        mask-size: 18px 18px
