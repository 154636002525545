@use "mixins" as *
.gameItem
  width: calc(50% - 10px)
  max-width: 150px
  flex: none
  margin: 10px 5px 0
  position: relative
  overflow: hidden
  border-radius: 6px
  background: var(--casino_gameItemRoundedBg)
  display: flex
  flex-direction: column
  max-height: 252px
  padding: 5px
  &:has(.bluredBg) > div
    background: none!important
  > div
    position: relative
    z-index: 2
    border: 0 !important
    > div
      width: 140px
      min-height: 140px
      height: 140px
      position: relative
      margin: 0 auto
      background: none!important

      :global(.imageNext)
        width: 100%
        height: 100%
        border-radius: 100%
        overflow: hidden
        display: block
        position: relative
        &::after
          content: ""
          position: absolute
          width: 100%
          height: 100%
          border-radius: 100%
          box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.7)

        img
          width: 100%!important
          position: absolute
          top: 50%
          transform: translateY(-50%)

    video
      width: 100%
      height: 100%
      object-fit: cover
      border-radius: 100%

.gameCategory
  @include flex
  justify-content: center
  min-height: 14px
  font-size: 12px
  line-height: 12px
  color: rgb(var(--primaryColor))
  text-transform: capitalize
  margin-bottom: 8px

.gameName
  @include bold
  color: #fff
  @include flex
  justify-content: center
  text-transform: capitalize
  padding: 10px 2px
  @include ellipsis
  width: 100%
  > span
    @include ellipsis
    max-width: 100%
    font-size: 16px
    line-height: 18px

.playNowBtn
  border-radius: var(--casino_gameItemRoundedBtnRadius)
  text-transform: uppercase
  min-width: 124px
  width: 100%
  height: 32px
  min-height: 32px
  background: var(--casinoCompact_playNowBtnBg)
  color: var(--casino_providerActiveColor)

  &:active
    background: var(--casinoCompact_playNowBtnActiveBg)

.unstarred
  min-width: 32px
  height: 32px
  background: rgba(0, 0, 0, .6)
  border-radius: 100%
  position: absolute
  z-index: 2
  right: 14px
  bottom: 0px
  @include flex
  justify-content: center
  &:before
    content: ""
    background-color: rgba(255,255,255, 0.7)
    @include star(20px)
    transition: all 0.8s
    transform: rotate(0deg)
    transform-origin: center
    animation: unstarred 0.5s ease-in
    pointer-events: none

    @keyframes unstarred
      50%
        width: 26px
        height: 26px
        mask-size: 26px 26px
      100%
        width: 20px
        height: 20px
        mask-size: 20px 20px

  &.starred:before
    background-color: var(--star_activeBg)
    animation: starred 0.5s ease-in
    transform: rotate(145deg)

    @keyframes starred
      50%
        width: 26px
        height: 26px
        mask-size: 26px 26px
      100%
        width: 18px
        height: 18px
        mask-size: 18px 18px

.bluredBg
  position: absolute
  left: 0px
  top: 0px
  right: 0px
  bottom: 0px
  border-radius: 5px
  justify-content: center
  overflow: hidden

  img
    width: auto
    height: 100%
    transform: scale(1.2)
    opacity: .5!important
    filter: blur(4px)

  + div > div :global(.imageNext)
    box-shadow: 0 0 6px black
